import { Layout } from '~/components/Meta/Layout';
import { Unauthorized } from '~/components/Meta/Unauthorized';
import { CAMPAIGNS_PATH } from '~/constants/routes';
import { getServerSidePropsWrapper } from '~/libs/getServerSideProps/getServerSidePropsWrapper';

export function HomePage() {
  return (
    <Layout meta="" title="BoneWorld Quest">
      <Unauthorized />
    </Layout>
  );
}

export default HomePage;

export const getServerSideProps = getServerSidePropsWrapper(
  async (_, { session }) => {
    if (session) {
      return {
        redirect: {
          destination: CAMPAIGNS_PATH,
          permanent: false,
        },
      };
    }
    return {
      props: {},
    };
  }
);
