export const IMAGES = {
  homepage: {
    src: '/bg-images/field.png',
    alt: 'A field with a mysterious machine floating in the sky above it',
  },
  peopleGenerate: {
    src: '/bg-images/tavern.png',
    alt: 'An urban tavern filled with revellers',
  },
  placesGenerate: {
    src: '/bg-images/festival.png',
    alt: 'A city celebrating a festival',
  },
  campaignsIndex: {
    src: '/bg-images/canyon.png',
    alt: 'A small party overlooking a canyon',
  },
  campaignsDetailDefault: {
    src: '/bg-images/swordsman.png',
    alt: 'A swordsman fighting a dragon',
  },
  campaignsEdit: {
    src: '/bg-images/ruins-map.png',
    alt: 'A group of adventurers plot their next move outside some ruins',
  },
  campaignsCreate: {
    src: '/bg-images/author.png',
    alt: 'An elderly writer sits at a desk working on a manuscript',
  },
  campaignsHousesCreate: {
    src: '/bg-images/wedding.png',
    alt: 'A wedding ceremony',
  },
  housesEdit: {
    src: '/bg-images/mansion-fire.png',
    alt: 'A family sits looking at their former home ablaze',
  },
  housesDetail: {
    src: '/bg-images/estate.png',
    alt: 'A large estate nestled into the side of a hill',
  },
  campaignsMaps: {
    src: '/bg-images/maps.png',
    alt: 'A witch holds a map in her scroll room',
  },
  campaignsMapsCreate: {
    src: '/bg-images/map-maker.png',
    alt: 'A man sits looking out over a tropical island sketching a map',
  },
} as const;
