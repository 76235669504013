import { Box, Button, styled, Typography } from '@mui/material';
import Image from 'next/image';
import { signIn } from 'next-auth/react';

import { ZERO_WIDTH_SPACE } from '~/constants/characterEntities';
import { IMAGES } from '~/constants/images';
import { beholdenFont } from '~/constants/theme';

const ImageBox = styled(Box)`
  mask-image: radial-gradient(
    ellipse 65% 60%,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0) 80%
  );
  z-index: -1;
`;

const Quest = styled('span')`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export function Unauthorized() {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap={1}
      height="calc(100vh - 200px)"
      justifyContent="center"
      textAlign="center"
      width="100%"
    >
      <ImageBox
        height="100%"
        left={0}
        overflow="hidden"
        position="absolute"
        right={0}
        top={0}
        width="100%"
      >
        <Image
          alt={IMAGES.homepage.alt}
          fill
          objectFit="cover"
          objectPosition="top"
          src={IMAGES.homepage.src}
        />
      </ImageBox>
      <Typography
        component="h2"
        display="flex"
        flexDirection="column"
        fontFamily={beholdenFont.style.fontFamily}
        fontWeight={500}
        lineHeight={0.8}
        padding={2}
        variant="h1"
      >
        Bone{ZERO_WIDTH_SPACE}World <Quest>Quest</Quest>
      </Typography>
      <Button variant="contained" onClick={() => signIn()}>
        Log In
      </Button>
    </Box>
  );
}
